import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useRealtimeRaycasting } from "@/hooks/use-real-time-raycasting";
import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { selectShowPointerMovePreview } from "@/store/view-options/view-options-selectors";
import { computeCameraTransformOrPosForIElement } from "@/utils/camera-transform";
import { parseVector2, parseVector3 } from "@faro-lotv/app-component-toolbox";
import { PointCloud } from "@faro-lotv/lotv";
import {
  ThreeEvent,
  Vector2 as Vector2Prop,
  useThree,
} from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useState } from "react";
import { Plane, Vector2, Vector3 } from "three";

export type WalkPointCloudProps = {
  /** The point cloud to render if one is available */
  pointCloud: PointCloudObject | null;

  /** True to show, visibility change will animate the point cloud opacity*/
  visible: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /**
   * Whether the point cloud should answser to pointer events via raycasting
   *
   * @default true By default the point cloud handles pointer events via efficient raycasting on the loaded LOD nodes
   */
  raycastEnabled?: boolean;

  /** Callback issued when a point of the point cloud is hovered by a mouse/touch */
  onPointHovered?(ev: ThreeEvent<PointerEvent>): void;

  /** Callback issued when a point of the point cloud is clicked by a mouse/touch */
  onPointClicked?(ev: ThreeEvent<DomEvent>): void;

  /** Callback issued when a point cloud is zoomed */
  onPointZoomed?(ev: ThreeEvent<DomEvent>): void;

  /**
   * Callback that provides a point at "eye level (1.7m)" above the floor when it is clicked
   * If no tool is acitve (e.g. measure or annotate), or if the shift key is pressed, this callback
   * is issued to move around the floor. Else, the point clik is issued through the 'onPointClicked'
   * callback.
   */
  onFloorClicked(point: Vector3): void;
};

/**
 * @returns The component for displaying a point cloud and returning when a click has occurred on the floor of said point cloud.
 */
export function WalkPointCloud({
  pointCloud,
  visible,
  clippingPlanes,
  raycastEnabled,
  onPointHovered,
  onPointClicked,
  onPointZoomed,
  onFloorClicked,
}: WalkPointCloudProps): JSX.Element | null {
  const activeTool = useAppSelector(selectActiveTool);
  const showPointerMovePreview = useAppSelector(selectShowPointerMovePreview);

  useRealtimeRaycasting(pointCloud, !activeTool);

  const camera = useThree((s) => s.camera);

  // A state is created to remember the pixel coordinates in which the pointer
  // was pressed, in order to distinguish pointer click from pointer drag.
  const [pointerDownCoords, setPointerDownCoords] = useState<Vector2Prop>(
    new Vector2(),
  );

  const onPointerDown = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      setPointerDownCoords(new Vector2(ev.clientX, ev.clientY));
    },
    [setPointerDownCoords],
  );

  const forwardClickToProperHandler = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      if (!pointCloud) return;
      // If cursor moved more than 5 pixels is a drag
      const pointerDown = parseVector2(pointerDownCoords);
      const delta =
        Math.abs(pointerDown.x - ev.clientX) +
        Math.abs(pointerDown.y - ev.clientY);
      if (delta > 5) return;

      if (
        ev.distance > 0.1 &&
        ev.object instanceof PointCloud &&
        ev.index !== undefined
      ) {
        // a point of the cloud has been clicked. Is it for a tool or to move around the floor?
        if (activeTool === null) {
          // Get or estimate the normal for the point clicked
          const normal = ev.object.computePointWorldNormal(ev.index);
          // If undefined it means that the point clicked didn't have enough neighbors close by
          // By returning, the onClick event will go onto the next intersection and check its neighbors
          if (!normal) return;

          // Cos of the angle
          const angle = normal.dot(camera.up);

          // |cos| > cos 30° --> flat surface (floor)
          const COS_30 = 0.86;
          if (Math.abs(angle) > COS_30) {
            const scale = pointCloud.iElement.pose?.scale?.y ?? 1;
            // Place the camera at HEIGHT above the clicked point
            const p = ev.point.clone();

            // Update the position of the point with a height offset for the camera
            // before passing on to callback function
            onFloorClicked(
              parseVector3(
                computeCameraTransformOrPosForIElement({
                  position: p.toArray(),
                  scale,
                }),
              ),
            );

            // We found a valid normal, stop event propagation so we don't get the other intersections
            ev.stopPropagation();
          }
        } else {
          onPointClicked?.(ev);
        }
      }
    },
    [
      pointCloud,
      pointerDownCoords,
      onPointClicked,
      activeTool,
      onFloorClicked,
      camera.up,
    ],
  );

  const onPointerMove = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      if (
        pointCloud !== null &&
        ev.distance > 0.1 &&
        ev.object instanceof PointCloud &&
        ev.index !== undefined
      ) {
        onPointHovered?.(ev);
      }
    },
    [pointCloud, onPointHovered],
  );

  if (!visible || !pointCloud) {
    return null;
  }

  return (
    <PointCloudRenderer
      pointCloud={pointCloud}
      clippingPlanes={clippingPlanes}
      raycastEnabled={raycastEnabled}
      onPointerDown={onPointerDown}
      onClick={forwardClickToProperHandler}
      onPointerMove={
        activeTool || showPointerMovePreview ? onPointerMove : undefined
      }
      onWheel={activeTool ? onPointZoomed : undefined}
    />
  );
}
