import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { isPointCloudObject } from "@/object-cache-type-guard";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCanAllMembersExportPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { setActiveSettingsMenu, ToolName } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { ClipSceneTool, ClipSceneToolProps } from "@/tools/clip-scene-tool";
import {
  PickingTools,
  PickingToolsProps,
  PickingToolsRef,
} from "@/tools/picking-tools";
import {
  PointerMovePreviewTool,
  PointerMovePreviewToolRef,
  usePointerMovePreviewTool,
} from "@/tools/pointer-move-preview-tool";
import {
  useDeactivateToolOnUnmount,
  useToggleToolVisibility,
} from "@/tools/use-toggle-tool-visibility";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { forwardRef, RefObject } from "react";

/**
 * This hook implements the logic to show the tools that the overview mode needs, according to the
 * user's settings and permissions. This hook also correctly deactivates all the tools and related
 * settings on unmount.
 *
 * @param isPointCloud Whether a point cloud is present among the active models
 */
function useOverviewToolsVisibility(isPointCloud: boolean): void {
  const dispatch = useAppDispatch();

  const { canWriteAnnotations } = useAnnotationPermissions();
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const canCreateArea = useAppSelector(selectHasFeature(Features.CreateArea));

  const transparency = useTransparencySettingsContext();

  const canUseExportMode = useCanUseExportMode();

  // Clipping tool require write permission to allow the creation of new areas
  useToggleToolVisibility(
    ToolName.clipping,
    hasWritePermission && isPointCloud,
    canCreateArea,
    false,
  );

  useToggleToolVisibility(
    ToolName.export,
    canUseExportMode && isPointCloud,
    true,
    false,
  );

  useToggleToolVisibility(ToolName.clipScene, true, true, false);
  useToggleToolVisibility(
    ToolName.annotation,
    canWriteAnnotations,
    true,
    false,
  );
  useToggleToolVisibility(ToolName.measurement, true, true, false);
  useToggleToolVisibility(ToolName.opacity, true, true, false);

  useToggleToolVisibility(ToolName.analysis, isPointCloud, true, false);

  useDeactivateToolOnUnmount();

  // Here a non-exhaustive useEffect is used, with the purpose
  // that the effect should not be run when the `transparency`
  // context changes, but only when the mode scene is exited and unmounted.
  // Usage of a normal 'useEffect' causes the opacity sliders to
  // close abruptly when the user sets the two sliders to full opaque.
  useNonExhaustiveEffect(
    () => () => {
      dispatch(setActiveSettingsMenu(null));
      transparency.resetCadAndCloudOpacities();
    },
    [dispatch],
  );
}

type OverviewToolsProps = PickingToolsProps &
  ClipSceneToolProps & {
    /** A reference to the pointer move preview component */
    pointerMovePreview: RefObject<PointerMovePreviewToolRef>;
  };

/** @returns the logic to controls what tool is active in 3D Overview Mode */
export const OverviewTools = forwardRef<PickingToolsRef, OverviewToolsProps>(
  function OverviewTools(
    {
      activeModels,
      onActiveToolChanged,
      areaBox,
      modelBox,
      clippingBoxChanging,
      clippingPlanesChanged,
      pointerMovePreview,
    },
    ref,
  ): JSX.Element | null {
    const activeTool = useAppSelector(selectActiveTool);
    usePointerMovePreviewTool(activeTool);

    const pointCloud = activeModels?.find((e) => isPointCloudObject(e));
    useOverviewToolsVisibility(pointCloud !== undefined);

    const showPreviewPlane = useAppSelector(
      selectHasFeature(Features.ObjectBasedMeasurement),
    );

    return (
      <>
        <PickingTools
          ref={ref}
          activeModels={activeModels}
          onActiveToolChanged={onActiveToolChanged}
        />
        <ClipSceneTool
          active={activeTool === ToolName.clipScene}
          areaBox={areaBox}
          modelBox={modelBox}
          clippingBoxChanging={clippingBoxChanging}
          clippingPlanesChanged={clippingPlanesChanged}
        />
        {showPreviewPlane && (
          <PointerMovePreviewTool
            pointcloud={pointCloud}
            ref={pointerMovePreview}
          />
        )}
      </>
    );
  },
);

/** @returns whether the current user is allowed to use the export mode */
function useCanUseExportMode(): boolean {
  // The point cloud export requires a userId to be started
  const { isLoggedIn } = useAuthContext();

  // Everyone with write permissions is allowed to export
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  // Users with read permissions can export, when it has been enabled for the company
  // We also allow the orthophoto export when the point cloud export is allowed.
  const canAllMembersExportPointCloud = useAppSelector(
    selectCanAllMembersExportPointCloud,
  );

  return isLoggedIn && (hasWritePermission || canAllMembersExportPointCloud);
}
