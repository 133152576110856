import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import { useCameraParametersIfAvailable } from "@/components/r3f/utils/camera-parameters";
import {
  ModeTransitionProps,
  SceneFilterLookAtInitialState,
} from "@/modes/mode";
import { useAppSelector } from "@/store/store-hooks";
import { selectBestCameraViewForElementInThreeSpace } from "@/utils/cam-view";
import { DEFAULT_PERSPECTIVE_FOV } from "@faro-lotv/lotv";
import { selectIElementWorldPosition } from "@faro-lotv/project-source";
import { useEffect, useMemo } from "react";
import { Vector3, Vector3Tuple } from "three";

/**
 * @returns the transition to enter OverviewMode
 */
export function OverviewTransition({
  initialState,
  onCompleted,
  modeCamera,
}: ModeTransitionProps<SceneFilterLookAtInitialState>): JSX.Element {
  const updatedState = useUpdateCameraParameter(initialState);
  useCameraParametersIfAvailable(modeCamera, updatedState?.camera, onCompleted);

  useEffect(() => {
    if (!initialState?.camera) {
      onCompleted();
    }
  }, [initialState?.camera, onCompleted]);

  return <SnapshotRenderer />;
}

const DISTANCE = 2.5;
const CAMERA_NEAR = 0.1;
const CAMERA_FAR = 1000;

/**
 * @returns an initial state with the camera parameters adjusted
 * @param initialState The initial state to update
 */
function useUpdateCameraParameter(
  initialState: ModeTransitionProps<SceneFilterLookAtInitialState>["initialState"],
): ModeTransitionProps<SceneFilterLookAtInitialState>["initialState"] {
  const target = useAppSelector(
    selectIElementWorldPosition(initialState?.lookAtId),
  );
  const savedViewForElement = useAppSelector(
    selectBestCameraViewForElementInThreeSpace(initialState?.lookAtId),
  );

  return useMemo(() => {
    // If the lookAtId is not defined or the camera is already defined, do not change anything
    if (!initialState?.lookAtId || initialState.camera) {
      return initialState;
    }

    // If the element to look at has a saved view, use it instead of a heuristic
    if (savedViewForElement) {
      initialState.camera = {
        near: CAMERA_NEAR,
        far: CAMERA_FAR,
        fov: DEFAULT_PERSPECTIVE_FOV,
        pos: savedViewForElement.position.toArray(),
        target,
      };

      return initialState;
    }

    // Move the camera a little up so we can see the scene from an angle
    const dir = new Vector3(target[0], 0, target[2])
      .normalize()
      .multiplyScalar(DISTANCE);

    const pos: Vector3Tuple = [
      target[0] - dir.x,
      target[1] - dir.y + DISTANCE,
      target[2] - dir.z,
    ];

    // These parameters are always the same in our app in 3D and never change
    initialState.camera = {
      near: CAMERA_NEAR,
      far: CAMERA_FAR,
      pos,
      target,
      fov: DEFAULT_PERSPECTIVE_FOV,
    };

    return initialState;
  }, [initialState, savedViewForElement, target]);
}
