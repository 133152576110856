import { useCached3DObjectsIfReady } from "@/object-cache";
import { isPointCloudObject } from "@/object-cache-type-guard";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { ClipSceneTool, ClipSceneToolProps } from "@/tools/clip-scene-tool";
import {
  PickingTools,
  PickingToolsProps,
  PickingToolsRef,
} from "@/tools/picking-tools";
import {
  PointerMovePreviewTool,
  PointerMovePreviewToolRef,
  usePointerMovePreviewTool,
} from "@/tools/pointer-move-preview-tool";
import { isValid } from "@faro-lotv/ielement-types";
import { forwardRef, RefObject, useMemo } from "react";
import { WalkSceneActiveElement, WalkSceneOverlayElement } from "./walk-types";

export type WalkToolsProps = Pick<PickingToolsProps, "onActiveToolChanged"> & {
  /** The current element of the scene */
  currentElement: WalkSceneActiveElement;
  /** The element that we want to render together with the current element */
  overlayElement?: WalkSceneOverlayElement;
  /** A reference to the pointer move preview component */
  pointerMovePreview: RefObject<PointerMovePreviewToolRef>;
} & ClipSceneToolProps;

/** @returns the logic to controls what tool is active in 3D Overview Mode */
export const WalkTools = forwardRef<PickingToolsRef, WalkToolsProps>(
  function WalkTools(
    {
      currentElement,
      overlayElement,
      onActiveToolChanged,
      areaBox,
      modelBox,
      clippingBoxChanging,
      clippingPlanesChanged,
      pointerMovePreview,
    },
    ref,
  ): JSX.Element | null {
    const activeTool = useAppSelector(selectActiveTool);
    usePointerMovePreviewTool(activeTool);

    const activeElements = useWalkModeActiveElements(
      currentElement,
      overlayElement,
    );
    const activeModels = useCached3DObjectsIfReady(activeElements);
    const showPreviewPlane = useAppSelector(
      selectHasFeature(Features.ObjectBasedMeasurement),
    );
    const pointCloud = activeModels.find((e) => isPointCloudObject(e));

    return (
      <>
        <PickingTools
          activeModels={activeModels}
          onActiveToolChanged={onActiveToolChanged}
          ref={ref}
        />
        <ClipSceneTool
          active={activeTool === ToolName.clipScene}
          areaBox={areaBox}
          modelBox={modelBox}
          clippingBoxChanging={clippingBoxChanging}
          clippingPlanesChanged={clippingPlanesChanged}
        />
        {showPreviewPlane && (
          <PointerMovePreviewTool
            pointcloud={pointCloud}
            ref={pointerMovePreview}
          />
        )}
      </>
    );
  },
);

/**
 * @returns The list of elements for which the picking tools are enabled
 * @param currentElement The current element of the scene
 * @param overlayElement The overlay element of the scene
 */
export function useWalkModeActiveElements(
  currentElement: WalkSceneActiveElement,
  overlayElement?: WalkSceneOverlayElement,
): WalkSceneActiveElement[] | undefined {
  return useMemo(
    () => [currentElement, overlayElement].filter(isValid),
    [currentElement, overlayElement],
  );
}
