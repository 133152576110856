import { CameraViewType } from "@/utils/cam-view";
import { useReproportionCamera } from "@faro-lotv/app-component-toolbox";
import {
  assert,
  DEFAULT_PERSPECTIVE_FOV,
  SupportedCamera,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OrthographicCamera, PerspectiveCamera } from "three";

export type ActiveCamera = {
  /** The currently active camera projection */
  cameraProjection: CameraViewType;
  /** Sets the currently active camera projection */
  setCameraProjection(projection: CameraViewType): void;
  /** The currently active camera */
  activeCamera: SupportedCamera;
  /** The default perspective camera */
  defaultPerspectiveCamera: PerspectiveCamera;
  /** Whether the projection switch buttons should be visible */
  isProjectionSwitchVisible: boolean;
  /** Sets whether the projection switch buttons should be visible */
  setProjectionSwitchVisible(visible: boolean): void;
};

export const ActiveCameraContext = createContext<ActiveCamera | undefined>(
  undefined,
);

/** @returns the Active Camera context */
export function useActiveCamera(): ActiveCamera {
  const ctx = useContext(ActiveCameraContext);
  assert(ctx, "Active camera context should be initialized");
  return ctx;
}

/** @returns a component to create the Active Camera Context */
export function ActiveCameraContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [cameraProjection, setCameraProjection] = useState(
    CameraViewType.perspective,
  );

  // Creating only once the default perspective camera
  const [perspectiveCamera] = useState(() => {
    const thePerspectiveCamera = new PerspectiveCamera();
    thePerspectiveCamera.name = "Default perspective camera";
    thePerspectiveCamera.fov = DEFAULT_PERSPECTIVE_FOV;
    return thePerspectiveCamera;
  });

  // Creating only once the default orthographic camera
  const [orthoCamera] = useState(() => {
    const theOrthoCamera = new OrthographicCamera();
    theOrthoCamera.name = "Default ortho camera";
    /**
     * If the manual property is not set or is false,
     * R3F automatically changes the camera when resizing the canvas
     */
    Object.assign(theOrthoCamera, { manual: true });
    return theOrthoCamera;
  });

  // The active camera is either the perspective or the orthographic camera
  const [activeCamera, setActiveCamera] =
    useState<SupportedCamera>(perspectiveCamera);

  // If the camera projection has changed, the active camera is changed to
  // the corresponding camera, keeping the camera pose unchanged.
  useEffect(() => {
    const newCamera =
      cameraProjection === CameraViewType.perspective
        ? perspectiveCamera
        : orthoCamera;
    if (activeCamera !== newCamera) {
      newCamera.position.copy(activeCamera.position);
      newCamera.quaternion.copy(activeCamera.quaternion);
      setActiveCamera(newCamera);
    }
  }, [cameraProjection, perspectiveCamera, orthoCamera, activeCamera]);

  const [isProjectionSwitchVisible, setProjectionSwitchVisible] =
    useState(true);

  const value = useMemo(
    () => ({
      cameraProjection,
      setCameraProjection,
      activeCamera,
      defaultPerspectiveCamera: perspectiveCamera,
      isProjectionSwitchVisible,
      setProjectionSwitchVisible,
    }),
    [
      cameraProjection,
      activeCamera,
      perspectiveCamera,
      isProjectionSwitchVisible,
    ],
  );

  return (
    <ActiveCameraContext.Provider value={value}>
      {children}
    </ActiveCameraContext.Provider>
  );
}

/** Ensures that the R3F camera always follows the active camera set by this context. */
export function useDefaultCamera(): void {
  const { activeCamera } = useActiveCamera();
  const { set } = useThree();
  useEffect(() => {
    set({ camera: activeCamera });
  }, [activeCamera, set]);

  useReproportionCamera(activeCamera);
}

/**
 * Hides the projection switch buttons when the component is mounted and shows them when it is unmounted.
 */
export function useHideProjectionSwitch(): void {
  const { setProjectionSwitchVisible } = useActiveCamera();
  useEffect(() => {
    setProjectionSwitchVisible(false);
    return () => {
      setProjectionSwitchVisible(true);
    };
  }, [setProjectionSwitchVisible]);
}

/**
 * While this hook is mounted, only the default perspective camera is allowed
 * to be set as the active camera.
 *
 * @returns The application default perspective camera
 */
export function useAllowOnlyPerspectiveCamera(): PerspectiveCamera {
  const { cameraProjection, setCameraProjection, defaultPerspectiveCamera } =
    useActiveCamera();
  // If the camera projection is orthographic, it is immediately set back to perspective
  useEffect(() => {
    if (cameraProjection === CameraViewType.orthographic) {
      setCameraProjection(CameraViewType.perspective);
    }
  }, [cameraProjection, setCameraProjection]);

  return defaultPerspectiveCamera;
}

/**
 * While this hook is mounted, only the default perspective camera will be used
 * as the rendering camera for the active R3F context. This hook is useful
 * for modes where the user is not allowed to switch to the default ortho camera.
 *
 * @returns The application default perspective camera
 */
export function useOnlyPerspectiveCamera(): PerspectiveCamera {
  const camera = useAllowOnlyPerspectiveCamera();
  useDefaultCamera();
  return camera;
}
